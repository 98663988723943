import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import {
  Slide,
  Paper,
  Button,
  Typography,
  Grid,
  makeStyles,
  Link
} from '@material-ui/core'

import { Layout } from 'components/layouts/login-layout'
import { RegistrationForm } from 'components/admin/registration-form'
import API_ENDPOINTS from 'constants/api-endpoints'
import CLIENT_ROUTES from 'constants/endpoints'
import { withErrorNotification } from 'components/hocs/with-error-notification'
import { getUser } from 'services/auth/auth'
import { CitySelection } from 'components/admin/common/city-selector'
import { UserContext } from 'components/global-context'
import { City } from 'types'

const useStyles = makeStyles(theme => ({
  registrationContainer: {
    position: 'relative'
  },
  formControl: {
    minWidth: 120,
  },
  requestCityContainer: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    height: '100%',
    width: '100%',
    padding: theme.spacing(1),
    zIndex: 1
  }
}));


const getCities =  () => {
  const { token } = getUser();
  return axios.get(`${process.env.GATSBY_API_URL}/cities`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    },
  )
}
const register =  (email: string, password: string) => {
  return axios
    .post(`${process.env.GATSBY_API_URL}${API_ENDPOINTS.REGISTER}`, {
      username: email,
      email,
      password
    });
}

const requestCityAdmin =  (cityId: number) => {
  const { token } = getUser();
  return axios.post(`${process.env.GATSBY_API_URL}/city-admin-requests`,
    {
      cityId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
}

const getCurrentUserAdminRequest =  () => {
  const { token } = getUser();
  return axios.get(`${process.env.GATSBY_API_URL}/city-admin-requests/current-user`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
}

interface AdminRequestData {
  city: City;
  status: 'new' | 'confirmed' | 'declined'
}

const RegistrationPage = (props: {
  setError: any;
}) => {
  const { t } = useTranslation();
  const context = React.useContext(UserContext);
  const [ isCityRequestActive, setCityRequestScreen ] = useState(!!(getUser().token));
  const [ isCityConfirmationActive, setCityConfirmStatus ] = useState(false);
  const [ cityData, setCityData ] = useState<City[]>();
  const [ adminRequestData, setAdminRequestData ] = useState<AdminRequestData>();
  const classes = useStyles();
  const getAndSaveCurrentAdminRequest = async () => {
    const response = await getCurrentUserAdminRequest();
    setAdminRequestData(response.data);
    if (response.data.city) { // a city has been requested
      setCityRequestScreen(false);
      setCityConfirmStatus(true);
    }
  }
  useEffect(() => {
    getCities().then(response => {setCityData(response.data)});
    getAndSaveCurrentAdminRequest();
  }, [])
  const onRegister = async ({ email, password }: {
    email: string;
    password: string;
  }) => {
    try {
      const { data: { user: { role, city }, jwt} } = await register(email, password);
      context && context.setUser({
        email,
        token: jwt,
        role: role.name,
        cityId: city && city.id,
        cityName: city && city.name,
      })
      const { data: cityData } = await getCities();
      setCityData(cityData);
      setCityRequestScreen(true)
    } catch (e) {
      props.setError(e)
    }
  }

  return (
    <Layout pageName={t('register.title')}>
      <Grid className={classes.registrationContainer}>
        <RegistrationForm
          onSubmitRegistration={onRegister}
        />
        <Slide direction='up' in={isCityRequestActive} mountOnEnter unmountOnExit>
          <Paper className={classes.requestCityContainer}>
            <RequestCityAdmin
              cities={cityData}
              onSubmitCity={async (cityId) => {
                try {
                  await requestCityAdmin(cityId);
                  getAndSaveCurrentAdminRequest();
                } catch (e) {
                  props.setError(e);
                }
              }} />
          </Paper>
        </Slide>
        <Slide direction='down' in={isCityConfirmationActive} mountOnEnter unmountOnExit>
          <Paper className={classes.requestCityContainer}>
            <ConfirmCityAdmin
              cityName={adminRequestData && adminRequestData.city.name || ''}
              status={adminRequestData && adminRequestData.status}
              onChangeCity={() => {
                setCityRequestScreen(true);
                setCityConfirmStatus(false);
              }} />
          </Paper>
        </Slide>
      </Grid>

    </Layout>
  )
}

interface ConfirmCityProps {
  cityName: string;
  onChangeCity: () => void;
  status?: 'new' | 'declined' | 'confirmed';
}

const ConfirmCityAdmin = ({cityName, onChangeCity, status}: ConfirmCityProps) => {
  const { t } = useTranslation();
  return (
    <Grid container direction='column' justify='center' alignItems='center' spacing={4}>
      <Grid item>
        <Typography align='center' variant='h4'>
          {t('register.confirm-admin-title', {city: cityName})}
        </Typography>
      </Grid>
      <Grid item>
        <Typography align='center'>
          {
            status === 'new' &&
              t('register.confirm-pending-text')
          }
          {
            status === 'confirmed' &&
              t('register.confirm-finished-text')
          }
          {
            status === 'declined' &&
            t('register.confirm-declined-text')
          }
        </Typography>
      </Grid>
      <Grid item >
        {
          (status === 'new' || status === 'declined') &&
              <Button
                variant='outlined'
                onClick={() => {
                  onChangeCity();
                }}
              >
                {t('register.change')}
              </Button>
        }
        {
          /**
             * Going to login to retrieve role and
             * city information. We could add a route
             * to refresh user information and go straight
             * to admin.
             */
          status === 'confirmed' &&
              <Link
                href={CLIENT_ROUTES.LOGIN}>
                { t('register.go-to-admin') }
              </Link>
        }
      </Grid>
    </Grid>
  )
}

interface RequestCityAdminProps {
  cities?: City[];
  onSubmitCity: (cityId: number) => void;
}



const RequestCityAdmin = ({ cities, onSubmitCity }: RequestCityAdminProps) => {
  const { t } = useTranslation();
  const [ selectedCity, setSelectedCity ] = useState<number>();
  return (
    <Grid container direction='column' justify='center' alignItems='center' spacing={4}>
      <Grid item>
        <Typography variant='h4'>
          {t('register.request-admin-title')}
        </Typography>
      </Grid>

      <Grid item>
        <CitySelection
          cities={cities}
          selectedCity={selectedCity}
          onSelectCity={(cityId) => {
            setSelectedCity(cityId as number)
          }}
        />
      </Grid>
      <Grid item>
        <Button
          variant='outlined'
          disabled={selectedCity === null}
          onClick={() => {
            if (selectedCity !== undefined) {
              onSubmitCity(selectedCity);
            }
          }}
        >
          {t('register.request')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default withErrorNotification(RegistrationPage);
