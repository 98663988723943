import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import { SubmissionErrors } from 'final-form'

import { Form } from '../final-form/form'
import { getFormTemplate } from './form-template'

export interface FormValues {
  email: string;
  password: string;
}

interface Props {
  onSubmitRegistration: (arg: FormValues) => Promise<SubmissionErrors | undefined>;
}

export const RegistrationForm = ({ onSubmitRegistration }: Props) => {
  const { t } = useTranslation()

  const schema = object().shape({
    email: string().email().required(t('validation.required')),
    password: string()
      .required(t('validation.required'))
      .min(6)
      .matches(/[a-z]+/, t('validation.lowercase'))
      .matches(/[A-Z]+/, t('validation.uppercase'))
      .matches(/[0-9]+/, t('validation.require-number')),
  })

  return (
    <>
      <Typography paragraph align='center' variant={'h5'}>
        {t('title.register')}
      </Typography>
      <Form
        onSubmit={onSubmitRegistration}
        validationSchema={schema}
        initialValues={{ email: '', password: '' }}
        render={getFormTemplate(t('button.register'))}
      />
    </>
  );
}
